import http from "../http-common"; 

class NameService {
  getAllNames(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/name/names`, searchDTO);
  }

  get(nameId) {
    return this.getRequest(`/name/${nameId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/name?field=${matchData}`, null);
  }

  addName(data) {
    return http.post("/name/addName", data);
  }

  update(data) {
  	return http.post("/name/updateName", data);
  }
  
  uploadImage(data,nameId) {
  	return http.postForm("/name/uploadImage/"+nameId, data);
  }

  updateNameFromSnake(nameId) {
    return this.getRequest("/name/updateNameFromSnake/"+nameId, null);
  }

  updateAllApplicationNamesFromSnake(applicationId) {
    return this.getRequest("/name/updateAllApplicationNamesFromSnake/"+applicationId, null);

  }



	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new NameService();
