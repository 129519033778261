import http from "../http-common"; 

class DomainClassService {
  getAllDomainClasses(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/domainClass/domainClasses`, searchDTO);
  }

  get(domainClassId) {
    return this.getRequest(`/domainClass/${domainClassId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/domainClass?field=${matchData}`, null);
  }

  addDomainClass(data) {
    return http.post("/domainClass/addDomainClass", data);
  }

  update(data) {
  	return http.post("/domainClass/updateDomainClass", data);
  }
  
  uploadImage(data,domainClassId) {
  	return http.postForm("/domainClass/uploadImage/"+domainClassId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new DomainClassService();
