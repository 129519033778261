
	<template>
		<div class="content">


				<table>
					<tr> 
						<td style="width: 100%;"></td>
				        	<td style="width: 150px;">
				      			<base-button class="btn btn-primary" @click="updateDomainClass()">Save</base-button>
				        	</td>
				        	<td style="width: 150px;">
				        	<td style="width: 150px;">
				        		
								<div >
								  <div class="container">
												    <base-button class="btn btn-primary" @click="modal2DomainClasses = true">Add</base-button>
								              <modal :show.sync="modal2DomainClasses">
								                <template slot="header">
								                  <h5 class="modal-title" id="exampleModalLabel">Add DomainClass</h5>
								                </template>
								                <div>
								                  <form @submit.prevent>
												                  </form>
								                </div>
								                <template slot="footer">
								                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
								                </template>
								              </modal>
												  </div>
								</div>
				        	</td>
					</tr> 
				</table>



				<table>
      <template>
        <div class="content">
          <a-modal :footer="null" :visible="showProgressBar" class="centered-modal" v-if="showProgressBar"
                   :closable="false">
            <div style="text-align: center; padding: 20px;">
              <h5>Please wait...</h5>
            </div>
          </a-modal>
        </div>
        <div class="content">
          <a-modal :footer="null" :visible="userPermissionDialog" :closable="false" class="centered-modal" v-if="userPermissionDialog">
            <div style="text-align: center; padding: 20px;">
              <h5>User does not have permission</h5>
              <button @click="handlePermissionDialogClose">OK</button>
            </div>
          </a-modal>
        </div>
      </template>

	
	
	
		
		
										<tr> 
					<td class="detail_view_column_2">Domain Class Id</td>
																							    		<td class="detail_view_column_1"><b> {{ domainClassDetails.domainClassId }} </b></td>
					    						    														</tr>
																																														<tr> 
					<td class="detail_view_column_2">Is Default View</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="IsDefaultView" type="text" placeholder="Enter IsDefaultView" v-model="domainClassDetails.isDefaultView"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Default To Picture View</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="DefaultToPictureView" type="text" placeholder="Enter DefaultToPictureView" v-model="domainClassDetails.defaultToPictureView"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Include Toggle View Button</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="IncludeToggleViewButton" type="text" placeholder="Enter IncludeToggleViewButton" v-model="domainClassDetails.includeToggleViewButton"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Default Sort Order</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="DefaultSortOrder" type="text" placeholder="Enter DefaultSortOrder" v-model="domainClassDetails.defaultSortOrder"></input>
																		</div>
						</td>
														</tr>
						

	
						<tr>
				<td>
					<div>Domain Class Name</div>		
				</td>
				<td  class="detail_view_column_1">
					<div>
					  <div>
						  <vue-autosuggest class="detail_view_input" label="DomainClassName" type="text" placeholder="Enter DomainClassName" v-model="domainClassDetails.domainClassName" :suggestions="filteredSuggestionsDomainClassName" :input-props="{id:'autosuggest__inputDomainClassName'}" @selected="selectHandlerDomainClassName" @input="onInputChangeDomainClassName" :getSuggestionValue="s => s.item.domainClassName.toString()">
						    <template slot-scope="{ suggestion }">
						      <div>
						        <b>{{suggestion.item.domainClassName}}</b>
						      </div>
						    </template>
						  </vue-autosuggest>
					  </div>
					</div>  
				</td>
			</tr>
								<tr>
				<td>
					<div>Application Name</div>		
				</td>
				<td  class="detail_view_column_1">
					<div>
					  <div>
						  <vue-autosuggest class="detail_view_input" label="ApplicationName" type="text" placeholder="Enter ApplicationName" v-model="domainClassDetails.applicationName" :suggestions="filteredSuggestionsApplicationName" :input-props="{id:'autosuggest__inputApplicationName'}" @selected="selectHandlerApplicationName" @input="onInputChangeApplicationName" :getSuggestionValue="s => s.item.applicationName.toString()">
						    <template slot-scope="{ suggestion }">
						      <div>
						        <b>{{suggestion.item.applicationName}}</b>
						      </div>
						    </template>
						  </vue-autosuggest>
					  </div>
					</div>  
				</td>
			</tr>
				
																	</table>
	
	




















			<a-tabs v-model:activeKey="activeKey">
												<a-tab-pane key="1" tab="Fields">
					<div class="table-full-width table-responsive text-left">
				
									          <field-table 
				          
				            v-if="fields && fields.length > 0"
				            :title="table1.title"
				            :sub-title="table1.subTitle"
				            :fields="fields"
				            :columns="table1.columns"
				          >
				          </field-table>
										</div>
				</a-tab-pane>
												<a-tab-pane key="2" tab="Detail View Tables">
					<div class="table-full-width table-responsive text-left">
				
									          <detail-view-table-table 
				          
				            v-if="detailViewTables && detailViewTables.length > 0"
				            :title="table2.title"
				            :sub-title="table2.subTitle"
				            :detailViewTables="detailViewTables"
				            :columns="table2.columns"
				          >
				          </detail-view-table-table>
										</div>
				</a-tab-pane>
												<a-tab-pane key="3" tab="Mapped Classes">
					<div class="table-full-width table-responsive text-left">
				
									          <mapped-class-table 
				          
				            v-if="mappedClasses && mappedClasses.length > 0"
				            :title="table3.title"
				            :sub-title="table3.subTitle"
				            :mappedClasses="mappedClasses"
				            :columns="table3.columns"
				          >
				          </mapped-class-table>
										</div>
				</a-tab-pane>
											</a-tabs>

		</div>
	</template>

<script>

import DomainClassService from "../services/DomainClassService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';
import NameTable from "@/components/NameTable";
import NameService from "../services/NameService";
import FieldTable from "@/components/FieldTable";
import FieldService from "../services/FieldService";
import DetailViewTableTable from "@/components/DetailViewTableTable";
import DetailViewTableService from "../services/DetailViewTableService";
import MappedClassTable from "@/components/MappedClassTable";
import MappedClassService from "../services/MappedClassService";
import ApplicationTable from "@/components/ApplicationTable";
import ApplicationService from "../services/ApplicationService";


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    domainClassId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
		FieldTable,
		DetailViewTableTable,
		MappedClassTable,
  },
  data() {
    return {
      domainClassToAdd: {},
      modal2DomainClasses: false,
      domainClassDetails: null,
      fields: [],
      detailViewTables: [],
      mappedClasses: [],

     activeKey: "1",
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
     activeKey: "1",
      table2: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
     activeKey: "1",
      table3: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },

      fileList,
      checked,
	  yearValue,



      domainClassNameQuery: "",
      suggestionsDomainClassName: [],
      suggestionsLoadedDomainClassName: false,

      applicationNameQuery: "",
      suggestionsApplicationName: [],
      suggestionsLoadedApplicationName: false,
    };
  },
  methods: {

    
    selectHandlerDomainClassName(item) {
      this.domainClassDetails.domainClassName = item.item.domainClassName.toString();
      this.domainClassDetails.nameId = item.item.nameId.toString();
      
    },

    async getDomainClassNameSuggestions()
    {
      try {
  		const searchDTO = {
			sortBy: "domainClassName", 
			sortOrder: "asc", 
			searchQuery: this.domainClassDetails.domainClassName, 
			page: "0", 
			size: "15"
		};
        let response = await ApplicationService.getAllApplications(searchDTO);
        this.suggestionsDomainClassName = response.data.applications;
        this.suggestionsLoadedDomainClassName = true;
      } catch (error) {
        console.error('Error fetching applications list for auto-complete: ', error);
      }
    },
    
	onInputChangeDomainClassName(text) {
      this.getDomainClassNameSuggestions();
    },
    selectHandlerApplicationName(item) {
      this.domainClassDetails.applicationName = item.item.applicationName.toString();
      this.domainClassDetails.applicationId = item.item.applicationId.toString();
      
    },

    async getApplicationNameSuggestions()
    {
      try {
  		const searchDTO = {
			sortBy: "applicationName", 
			sortOrder: "asc", 
			searchQuery: this.domainClassDetails.applicationName, 
			page: "0", 
			size: "15"
		};
        let response = await NameService.getAllNames(searchDTO);
        this.suggestionsApplicationName = response.data.names;
        this.suggestionsLoadedApplicationName = true;
      } catch (error) {
        console.error('Error fetching names list for auto-complete: ', error);
      }
    },
    
	onInputChangeApplicationName(text) {
      this.getApplicationNameSuggestions();
    },

	
	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

    async handleAddSubmitted() {
      this.modal2DomainClasses = false;

      const jsonData = JSON.stringify(this.domainClassToAdd);
      console.log(jsonData);
      
      const res = await DomainClassService.addDomainClass(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateDomainClass() {

      const jsonData = JSON.stringify(this.domainClassDetails);
      const res = await DomainClassService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalDomainClasses = false;
//        this.getAllDomainClasses();
      }
    },


    async getDomainClassDetails() {
      try {
			let response = await DomainClassService.get(this.domainClassId);
			this.domainClassDetails = response.data;

			let hasAnswers = 0;



			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	      try {
      		const searchDTO = {
				sortBy: "fieldId", 
				sortOrder: "asc", 
				searchQuery: "", 
				page: "0", 
				size: "50",
				domainClassId: this.domainClassId 
    		};
			const response = await FieldService.getAllFields(searchDTO);

		    if (!response.data.empty) {
				this.fields = response.data.fields;
			}

//	        console.log(this.fields);
      
	      } catch (error) {
	        console.error("Error fetching fields:", error);
	      }
	      try {
      		const searchDTO = {
				sortBy: "detailViewTableId", 
				sortOrder: "asc", 
				searchQuery: "", 
				page: "0", 
				size: "50",
				domainClassId: this.domainClassId 
    		};
			const response = await DetailViewTableService.getAllDetailViewTables(searchDTO);

		    if (!response.data.empty) {
				this.detailViewTables = response.data.detailViewTables;
			}

//	        console.log(this.detailViewTables);
      
	      } catch (error) {
	        console.error("Error fetching detailViewTables:", error);
	      }
	      try {
      		const searchDTO = {
				sortBy: "mappedClassId", 
				sortOrder: "asc", 
				searchQuery: "", 
				page: "0", 
				size: "50",
				domainClassId: this.domainClassId 
    		};
			const response = await MappedClassService.getAllMappedClasses(searchDTO);

		    if (!response.data.empty) {
				this.mappedClasses = response.data.mappedClasses;
			}

//	        console.log(this.mappedClasses);
      
	      } catch (error) {
	        console.error("Error fetching mappedClasses:", error);
	      }
	    
      } catch (error) {
        console.error('Error fetching domainClass details:', error);
      }
    },


	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getDomainClassDetails();
  },
  computed: {
	  filteredSuggestionsDomainClassName() {
	    if (!this.suggestionsLoadedDomainClassName)
	      return [];
	    this.domainClassNameQuery = this.domainClassDetails.domainClassName.toString();
	    return [
	      {
	        data: this.suggestionsDomainClassName
	      }
	    ];
	  },
	  filteredSuggestionsApplicationName() {
	    if (!this.suggestionsLoadedApplicationName)
	      return [];
	    this.applicationNameQuery = this.domainClassDetails.applicationName.toString();
	    return [
	      {
	        data: this.suggestionsApplicationName
	      }
	    ];
	  },
  },
  
};
</script>
<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.domainClass-image {
				    	height: 250px;
			}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}



</style>
