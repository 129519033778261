<template>
  <div class="container">
    <div class="create-application">
      <h3>Let's create an EB app in 60 seconds!</h3>
    </div>

    <textarea 
      class="creation-prompt" 
      placeholder="Briefly describe your idea for a web app. 
      'an eBay clone called myEbayClone'
      'an air travel reservation platform called flyMeThere'"
      v-model="applicationToAdd.creationPrompt"
      rows="5">
    </textarea>

    <!-- Dropdown selectors for FrontEnd, BackEnd, Database (horizontal layout) -->
    <div class="form-row">
      <div class="form-group">
        <label class="form-label">FrontEnd</label>
        <select v-model="applicationToAdd.frontEnd" class="form-control">
          <option value="VueJS">VueJS</option>
          <option value="Svelte">Svelte</option>
          <option value="ReactJS">ReactJS</option>
          <option value="AngularJS">AngularJS</option>
        </select>
      </div>

      <div class="form-group">
        <label class="form-label">BackEnd</label>
        <select v-model="applicationToAdd.backEnd" class="form-control">
          <option value="Spring Boot">Spring Boot</option>
          <option value="NodeJS">NodeJS</option>
          <option value="GoLang">GoLang</option>
          <option value="Python">Python</option>
        </select>
      </div>

      <div class="form-group">
        <label class="form-label">Database</label>
        <select v-model="applicationToAdd.database" class="form-control">
          <option value="MariaDB">MariaDB</option>
          <option value="PostGreSQL">PostGreSQL</option>
          <option value="MongoDB">MongoDB</option>
          <option value="DynamoDB">DynamoDB</option>
        </select>
      </div>
    </div>

    <button type="button" class="btn-starterup" @click="handleAddSubmitted()">Create Now</button>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import ApplicationService from "../services/ApplicationService";

export default {
  data() {
    return {
      rememberMe: true,
      applicationToAdd: {
        creationPrompt: '',
        frontEnd: 'VueJS',  // default selection
        backEnd: 'Spring Boot',  // default selection
        database: 'MariaDB'  // default selection
      },
    };
  },
  methods: {
    async handleAddSubmitted() {
      this.modalApplications = false;

      const currentDate = new Date().getTime();
      this.applicationToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.applicationToAdd);
      console.log(jsonData);
      
      const res = await ApplicationService.addApplication(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f7f9fc;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.create-application h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.creation-prompt {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 16px;
  resize: none;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-label {
  font-weight: bold;
  text-align: left; /* Align the labels to the left */
  margin-bottom: 5px;
}

.form-control {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.btn-starterup {
  display: inline-block;
  padding: 12px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-starterup:hover {
  background-color: #0056b3;
}
</style>

