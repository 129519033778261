<template>
  <div>
    <div class="row card-container">
      <div
        class="col application-card"
        v-for="app in applications"
        :key="app.applicationId"
      >
        <a
          :href="'#application/' + app.applicationId"
          target="_blank"
          class="application-link"
        >
          <img
            class="application-image"
            :alt="app.applicationNumber"
            :src="`/images/${app.imageFile}`"
          />
          <!-- Tooltip Element -->
          <div class="tooltip">
                        <p>{{ app.applicationSidebarLong }}</p>
          </div>
        </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {
    },
    props: {
        applications: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.application-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.application-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 30px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.application-image {
	    max-width: 200px;
	    height: 45px;
        	border-radius: 4px;
    }

.application-info {
    margin-top: 0px;
}

.application-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.application-details p {
    margin-top: 0px;
    text-align: left;
}


.application-card {
  position: relative; /* Position relative to allow absolute positioning of the tooltip */
}
.tooltip {
  position: absolute;
  width: 150px;
  bottom: 0px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(5, 5, 5, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none; /* Ensure the tooltip doesn't interfere with the hover effect */
}

.application-card:hover .tooltip {
  opacity: 1; /* Show the tooltip on hover */
}
</style>
  
