
	<template>
		<div class="content">


				<table>
					<tr> 
						<td style="width: 100%;"></td>
				        	<td style="width: 150px;">
				      			<base-button class="btn btn-primary" @click="updateField()">Save</base-button>
				        	</td>
				        	<td style="width: 150px;">
				        	<td style="width: 150px;">
				        		
								<div >
								  <div class="container">
												    <base-button class="btn btn-primary" @click="modal2Fields = true">Add</base-button>
								              <modal :show.sync="modal2Fields">
								                <template slot="header">
								                  <h5 class="modal-title" id="exampleModalLabel">Add Field</h5>
								                </template>
								                <div>
								                  <form @submit.prevent>
												                  </form>
								                </div>
								                <template slot="footer">
								                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
								                </template>
								              </modal>
												  </div>
								</div>
				        	</td>
					</tr> 
				</table>



				<table>
      <template>
        <div class="content">
          <a-modal :footer="null" :visible="showProgressBar" class="centered-modal" v-if="showProgressBar"
                   :closable="false">
            <div style="text-align: center; padding: 20px;">
              <h5>Please wait...</h5>
            </div>
          </a-modal>
        </div>
        <div class="content">
          <a-modal :footer="null" :visible="userPermissionDialog" :closable="false" class="centered-modal" v-if="userPermissionDialog">
            <div style="text-align: center; padding: 20px;">
              <h5>User does not have permission</h5>
              <button @click="handlePermissionDialogClose">OK</button>
            </div>
          </a-modal>
        </div>
      </template>

	
	
	
		
		
										<tr> 
					<td class="detail_view_column_2">Field Id</td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.fieldId }} </b></td>
					    						    														</tr>
																																																									<tr> 
					<td class="detail_view_column_2">Is Link Field </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isLinkField }} </b></td>
					    						    														</tr>
																					<tr> 
					<td class="detail_view_column_2">Is Add Field </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isAddField }} </b></td>
					    						    														</tr>
															<tr> 
					<td class="detail_view_column_2">Is Search Field </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isSearchField }} </b></td>
					    						    														</tr>
															<tr> 
					<td class="detail_view_column_2">Is Updatable </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isUpdatable }} </b></td>
					    						    														</tr>
															<tr> 
					<td class="detail_view_column_2">Is Default Visible </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isDefaultVisible }} </b></td>
					    						    														</tr>
															<tr> 
					<td class="detail_view_column_2">Is Detail View Visible </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isDetailViewVisible }} </b></td>
					    						    														</tr>
															<tr> 
					<td class="detail_view_column_2">Is Detail View Input </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isDetailViewInput }} </b></td>
					    						    														</tr>
															<tr> 
					<td class="detail_view_column_2">Is Auto Suggest </td>
																							    		<td class="detail_view_column_1"><b> {{ fieldDetails.isAutoSuggest }} </b></td>
					    						    														</tr>
												

	
						<tr>
				<td>
					<div>Field Name</div>		
				</td>
				<td  class="detail_view_column_1">
					<div>
					  <div>
						  <vue-autosuggest class="detail_view_input" label="FieldName" type="text" placeholder="Enter FieldName" v-model="fieldDetails.fieldName" :suggestions="filteredSuggestionsFieldName" :input-props="{id:'autosuggest__inputFieldName'}" @selected="selectHandlerFieldName" @input="onInputChangeFieldName" :getSuggestionValue="s => s.item.fieldName.toString()">
						    <template slot-scope="{ suggestion }">
						      <div>
						        <b>{{suggestion.item.fieldName}}</b>
						      </div>
						    </template>
						  </vue-autosuggest>
					  </div>
					</div>  
				</td>
			</tr>
								<tr>
				<td>
					<div>Domain Class Name</div>		
				</td>
				<td  class="detail_view_column_1">
					<div>
					  <div>
						  <vue-autosuggest class="detail_view_input" label="DomainClassName" type="text" placeholder="Enter DomainClassName" v-model="fieldDetails.domainClassName" :suggestions="filteredSuggestionsDomainClassName" :input-props="{id:'autosuggest__inputDomainClassName'}" @selected="selectHandlerDomainClassName" @input="onInputChangeDomainClassName" :getSuggestionValue="s => s.item.domainClassName.toString()">
						    <template slot-scope="{ suggestion }">
						      <div>
						        <b>{{suggestion.item.domainClassName}}</b>
						      </div>
						    </template>
						  </vue-autosuggest>
					  </div>
					</div>  
				</td>
			</tr>
								<tr>
				<td>
					<div>Field Type</div>		
				</td>
				<td  class="detail_view_column_1">
					<div>
					  <div>
						  <vue-autosuggest class="detail_view_input" label="FieldType" type="text" placeholder="Enter FieldType" v-model="fieldDetails.fieldType" :suggestions="filteredSuggestionsFieldType" :input-props="{id:'autosuggest__inputFieldType'}" @selected="selectHandlerFieldType" @input="onInputChangeFieldType" :getSuggestionValue="s => s.item.fieldType.toString()">
						    <template slot-scope="{ suggestion }">
						      <div>
						        <b>{{suggestion.item.fieldType}}</b>
						      </div>
						    </template>
						  </vue-autosuggest>
					  </div>
					</div>  
				</td>
			</tr>
				
		</table>
	
	




















			<a-tabs v-model:activeKey="activeKey">
											</a-tabs>

		</div>
	</template>

<script>

import FieldService from "../services/FieldService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';
import FieldTypeTable from "@/components/FieldTypeTable";
import FieldTypeService from "../services/FieldTypeService";
import ApplicationTable from "@/components/ApplicationTable";
import ApplicationService from "../services/ApplicationService";
import DomainClassTable from "@/components/DomainClassTable";
import DomainClassService from "../services/DomainClassService";


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    fieldId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      fieldToAdd: {},
      modal2Fields: false,
      fieldDetails: null,


      fileList,
      checked,
	  yearValue,



      fieldNameQuery: "",
      suggestionsFieldName: [],
      suggestionsLoadedFieldName: false,

      domainClassNameQuery: "",
      suggestionsDomainClassName: [],
      suggestionsLoadedDomainClassName: false,

      fieldTypeQuery: "",
      suggestionsFieldType: [],
      suggestionsLoadedFieldType: false,
    };
  },
  methods: {

    
    selectHandlerFieldName(item) {
      this.fieldDetails.fieldName = item.item.fieldName.toString();
      this.fieldDetails.nameId = item.item.nameId.toString();
      
    },

    async getFieldNameSuggestions()
    {
      try {
  		const searchDTO = {
			sortBy: "fieldName", 
			sortOrder: "asc", 
			searchQuery: this.fieldDetails.fieldName, 
			page: "0", 
			size: "15"
		};
        let response = await ApplicationService.getAllApplications(searchDTO);
        this.suggestionsFieldName = response.data.applications;
        this.suggestionsLoadedFieldName = true;
      } catch (error) {
        console.error('Error fetching applications list for auto-complete: ', error);
      }
    },
    
	onInputChangeFieldName(text) {
      this.getFieldNameSuggestions();
    },
    selectHandlerDomainClassName(item) {
      this.fieldDetails.domainClassName = item.item.domainClassName.toString();
      this.fieldDetails.domainClassId = item.item.domainClassId.toString();
      
    },

    async getDomainClassNameSuggestions()
    {
      try {
  		const searchDTO = {
			sortBy: "domainClassName", 
			sortOrder: "asc", 
			searchQuery: this.fieldDetails.domainClassName, 
			page: "0", 
			size: "15"
		};
        let response = await DomainClassService.getAllDomainClasses(searchDTO);
        this.suggestionsDomainClassName = response.data.domainClasses;
        this.suggestionsLoadedDomainClassName = true;
      } catch (error) {
        console.error('Error fetching domain_classes list for auto-complete: ', error);
      }
    },
    
	onInputChangeDomainClassName(text) {
      this.getDomainClassNameSuggestions();
    },
    selectHandlerFieldType(item) {
      this.fieldDetails.fieldType = item.item.fieldType.toString();
      this.fieldDetails.fieldTypeId = item.item.fieldTypeId.toString();
      
    },

    async getFieldTypeSuggestions()
    {
      try {
  		const searchDTO = {
			sortBy: "fieldType", 
			sortOrder: "asc", 
			searchQuery: this.fieldDetails.fieldType, 
			page: "0", 
			size: "15"
		};
        let response = await FieldTypeService.getAllFieldTypes(searchDTO);
        this.suggestionsFieldType = response.data.fieldTypes;
        this.suggestionsLoadedFieldType = true;
      } catch (error) {
        console.error('Error fetching field_types list for auto-complete: ', error);
      }
    },
    
	onInputChangeFieldType(text) {
      this.getFieldTypeSuggestions();
    },

	
	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

    async handleAddSubmitted() {
      this.modal2Fields = false;

      const jsonData = JSON.stringify(this.fieldToAdd);
      console.log(jsonData);
      
      const res = await FieldService.addField(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateField() {

      const jsonData = JSON.stringify(this.fieldDetails);
      const res = await FieldService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalFields = false;
//        this.getAllFields();
      }
    },


    async getFieldDetails() {
      try {
			let response = await FieldService.get(this.fieldId);
			this.fieldDetails = response.data;

			let hasAnswers = 0;



			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	    
      } catch (error) {
        console.error('Error fetching field details:', error);
      }
    },


	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getFieldDetails();
  },
  computed: {
	  filteredSuggestionsFieldName() {
	    if (!this.suggestionsLoadedFieldName)
	      return [];
	    this.fieldNameQuery = this.fieldDetails.fieldName.toString();
	    return [
	      {
	        data: this.suggestionsFieldName
	      }
	    ];
	  },
	  filteredSuggestionsDomainClassName() {
	    if (!this.suggestionsLoadedDomainClassName)
	      return [];
	    this.domainClassNameQuery = this.fieldDetails.domainClassName.toString();
	    return [
	      {
	        data: this.suggestionsDomainClassName
	      }
	    ];
	  },
	  filteredSuggestionsFieldType() {
	    if (!this.suggestionsLoadedFieldType)
	      return [];
	    this.fieldTypeQuery = this.fieldDetails.fieldType.toString();
	    return [
	      {
	        data: this.suggestionsFieldType
	      }
	    ];
	  },
  },
  
};
</script>
<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.field-image {
				    	height: 250px;
			}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}



</style>
