import http from "../http-common"; 

class UserService {
  getAllUsers(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/user/users`, searchDTO);
  }

  get(userId) {
    return this.getRequest(`/user/${userId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/user?field=${matchData}`, null);
  }

  addUser(data) {
    return http.post("/user/addUser", data);
  }

  update(data) {
  	return http.post("/user/updateUser", data);
  }
  
  uploadImage(data,userId) {
  	return http.postForm("/user/uploadImage/"+userId, data);
  }



	signIn(data) {
  		return http.post("/user/signin", data);
	}

	signUp(data) {
  		return http.post("/user/signup", data);
	}

	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new UserService();
