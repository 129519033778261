import http from "../http-common"; 

class MappedClassService {
  getAllMappedClasses(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/mappedClass/mappedClasses`, searchDTO);
  }

  get(mappedClassId) {
    return this.getRequest(`/mappedClass/${mappedClassId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/mappedClass?field=${matchData}`, null);
  }

  addMappedClass(data) {
    return http.post("/mappedClass/addMappedClass", data);
  }

  update(data) {
  	return http.post("/mappedClass/updateMappedClass", data);
  }
  
  uploadImage(data,mappedClassId) {
  	return http.postForm("/mappedClass/uploadImage/"+mappedClassId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new MappedClassService();
