
	<template>
		<div class="content">

   <table class="application-image-table">
      <tr> 
        <td></td>
        <td class="centered-cell">
          <img class="application-image" :src="`/images/${applicationDetails.imageFile}`" />
        </td>
      </tr>
    </table>
    
    
			<a-tabs v-model:activeKey="activeKey">
			   <a-tab-pane key="1" tab="Details">
				<table>
															<tr> 
					<td class="detail_view_column_2">Front End</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
                    				<img class="database-image" :src="`/images/vuejs.png`" />
                          <!--  <input label="DatabaseType" type="text" placeholder="Enter DatabaseType" v-model="applicationDetails.databaseType"></input> -->
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Back End</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
                    				<img class="database-image" :src="`/images/javaspringboot.png`" />
                          <!--  <input label="DatabaseType" type="text" placeholder="Enter DatabaseType" v-model="applicationDetails.databaseType"></input> -->
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Database Type</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
                    				<img class="database-image" :src="`/images/mariadb.png`" />
                          <!--  <input label="DatabaseType" type="text" placeholder="Enter DatabaseType" v-model="applicationDetails.databaseType"></input> -->
																		</div>
						</td>
														</tr>
										<tr> 
					<td class="detail_view_column_2">Application Id</td>
																							    		<td class="detail_view_column_1"><b> {{ applicationDetails.applicationId }} </b></td>
					    						    														</tr>
						<tr>
				<td>
					<div>Application Name</div>		
				</td>
				<td  class="detail_view_column_1">
					<div>
					  <div>
						  <vue-autosuggest class="detail_view_input" label="ApplicationName" type="text" placeholder="Enter ApplicationName" v-model="applicationDetails.applicationName" :suggestions="filteredSuggestionsApplicationName" :input-props="{id:'autosuggest__inputApplicationName'}" @selected="selectHandlerApplicationName" @input="onInputChangeApplicationName" :getSuggestionValue="s => s.item.applicationName.toString()">
						    <template slot-scope="{ suggestion }">
						      <div>
						        <b>{{suggestion.item.applicationName}}</b>
						      </div>
						    </template>
						  </vue-autosuggest>
					  </div>
					</div>  
				</td>
			</tr>

																										<tr> 
					<td class="detail_view_column_2">Package Name</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="PackageName" type="text" placeholder="Enter PackageName" v-model="applicationDetails.packageName"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Package Path</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="PackagePath" type="text" placeholder="Enter PackagePath" v-model="applicationDetails.packagePath"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Application Top Long</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="ApplicationTopLong" type="text" placeholder="Enter ApplicationTopLong" v-model="applicationDetails.applicationTopLong"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Application Sidebar Long</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="ApplicationSidebarLong" type="text" placeholder="Enter ApplicationSidebarLong" v-model="applicationDetails.applicationSidebarLong"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Database Name</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="DatabaseName" type="text" placeholder="Enter DatabaseName" v-model="applicationDetails.databaseName"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Image File</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="applicationDetails.imageFile"></input>
																		</div>
						</td>
																<td class="detail_view_column_3">
						</td>
														
						<td class="detail_view_column_4">
						</td>
									</tr>
						

	
				
												</table>
					</a-tab-pane>
                <a-tab-pane key="2" tab="Domain Classes">
                    <div>
                        <div class="row card-container">
                            <div class="col domain-class-card" v-for="domainClass in domainClasses" :key="domainClass.domainClassId">
                                

				                <a :href="'#domainClass/' +  domainClass.domainClassId"  target="_blank" class="domain-class-link">{{domainClass.domainClassName}}</a>

                            </div>
                        </div>
                    </div>
				</a-tab-pane>
                <a-tab-pane key="3" tab="Submenus">
					<div >
				
									          <submenu-table 
				          
				            v-if="submenus && submenus.length > 0"
				            :title="table2.title"
				            :sub-title="table2.subTitle"
				            :submenus="submenus"
				            :columns="table2.columns"
				          >
				          </submenu-table>
										</div>
				</a-tab-pane>
                <a-tab-pane key="4" tab="Integrations">
                    <div class="row card-container">
                    				<img class="integration-image" :src="`/images/spring-security.png`" />
                    				<img class="integration-image" :src="`/images/amazon-s3.png`" />			    
                    				<img class="integration-image" :src="`/images/openai.png`" />
                    				<img class="integration-image" :src="`/images/dalle3.png`" />			    
                    				<img class="integration-image" :src="`/images/email.png`" />



                    </div>
				</a-tab-pane>
			   <a-tab-pane key="5" tab="Prompt">
			      <div>
					<pre class="text-file-content">{{ applicationDetails.promptFileContent }}</pre>
			        <pre></pre>
			      </div>
			    </a-tab-pane>
			   <a-tab-pane key="6" tab="Model">
			      <div>
					<pre class="text-file-content">{{ applicationDetails.modelFileContent }}</pre>
			        <pre></pre>
			      </div>
			    </a-tab-pane>
			   <a-tab-pane key="7" tab="Config">
			      <div>
					<pre class="text-file-content">{{ applicationDetails.sqlFileContent }}</pre>
			        <pre></pre>
			      </div>
			    </a-tab-pane>
			   <a-tab-pane key="8" tab="CreateDB">
			      <div>
					<pre class="text-file-content">{{ applicationDetails.sqlCreateDatabaseFileContent }}</pre>
			        <pre></pre>
			      </div>
			    </a-tab-pane>
			   <a-tab-pane key="9" tab="Data">
			      <div>
					<pre class="text-file-content">{{ applicationDetails.sqlMockDataFileContent }}</pre>
			        <pre></pre>
			      </div>
			    </a-tab-pane>


			   <a-tab-pane key="10" tab="Build">
			      <div>
		<table>
			<tr> 
				<td class="detail_view_column_1"></td>
				<td rowspan="50" style="vertical-align: bottom;">
					<br><button class="btn-starterup" @click="generateCode()">Reprompt</button>
					<br><button class="btn-starterup" @click="generateCode()">Build Spec</button>
					<br><button class="btn-starterup" @click="generateCode()">Generate Code</button>
					<br><button class="btn-starterup" @click="cloneApplication()">Clone Application</button>
				</td>
				<td class="detail_view_column_2"></td>
			</tr> 
		</table>
			      </div>
			    </a-tab-pane>

            </a-tabs>

		</div>
	</template>

<script>

import ApplicationService from "../services/ApplicationService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';
import NameTable from "@/components/NameTable";
import NameService from "../services/NameService";
import SubmenuTable from "@/components/SubmenuTable";
import SubmenuService from "../services/SubmenuService";
import DomainClassTable from "@/components/DomainClassTable";
import DomainClassService from "../services/DomainClassService";


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    applicationId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
		DomainClassTable,
		SubmenuTable,
  },
  data() {
    return {
      applicationToAdd: {},
      modal2Applications: false,
      applicationDetails: null,
      domainClasses: [],
      submenus: [],

     activeKey: "1",
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
     activeKey: "1",
      table2: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },

      fileList,
      checked,
	  yearValue,
	  
	  fileContent: "",

      applicationNameQuery: "",
      suggestionsApplicationName: [],
      suggestionsLoadedApplicationName: false,
    };
  },
  methods: {

    
    selectHandlerApplicationName(item) {
      this.applicationDetails.applicationName = item.item.applicationName.toString();
      this.applicationDetails.nameId = item.item.nameId.toString();
      
    },

    async getApplicationNameSuggestions()
    {
      try {
  		const searchDTO = {
			sortBy: "applicationName", 
			sortOrder: "asc", 
			searchQuery: this.applicationDetails.applicationName, 
			page: "0", 
			size: "15"
		};
        let response = await NameService.getAllNames(searchDTO);
        this.suggestionsApplicationName = response.data.names;
        this.suggestionsLoadedApplicationName = true;
      } catch (error) {
        console.error('Error fetching names list for auto-complete: ', error);
      }
    },
    
	onInputChangeApplicationName(text) {
      this.getApplicationNameSuggestions();
    },

	
	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

    async handleAddSubmitted() {
      this.modal2Applications = false;

      const jsonData = JSON.stringify(this.applicationToAdd);
      console.log(jsonData);
      
      const res = await ApplicationService.addApplication(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateApplication() {

      const jsonData = JSON.stringify(this.applicationDetails);
      const res = await ApplicationService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalApplications = false;
//        this.getAllApplications();
      }
    },

    async fetchModelFileContent() {
      try {
//       const response = await fetch(this.applicationDetails.sqlfile);
		const response = await fetch('/modelfiles/drcv-' + this.applicationDetails.applicationName + '-data-model.csv');
        this.applicationDetails.modelFileContent = await response.text();
      } catch (error) {
        console.error("Error fetching the text file:", error);
      }
    },

    async fetchSqlFileContent() {
      try {
//       const response = await fetch(this.applicationDetails.sqlfile);
		const response = await fetch('/sqlfiles/drcv-' + this.applicationDetails.applicationName + '.sql');
        this.applicationDetails.sqlFileContent = await response.text();
      } catch (error) {
        console.error("Error fetching the text file:", error);
      }
    },

    async fetchSqlMockDataFileContent() {
      try {
//       const response = await fetch(this.applicationDetails.sqlfile);
		const response = await fetch('/sqlfiles/insert-mock-data-' + this.applicationDetails.applicationName + '.sql');
        this.applicationDetails.sqlMockDataFileContent = await response.text();
      } catch (error) {
        console.error("Error fetching the text file:", error);
      }
    },

    async fetchSqlCreateDatabaseFileContent() {
      try {
//       const response = await fetch(this.applicationDetails.sqlfile);
		const response = await fetch('/sqlfiles/create-' + this.applicationDetails.applicationName + '.sql');
        this.applicationDetails.sqlCreateDatabaseFileContent = await response.text();
      } catch (error) {
        console.error("Error fetching the text file:", error);
      }
    },

    async fetchPromptFileContent() {
      try {
//       const response = await fetch(this.applicationDetails.sqlfile);
		const response = await fetch('/prompts/' + this.applicationDetails.applicationName +'-creation-prompt.txt');
        this.applicationDetails.promptFileContent = await response.text();
      } catch (error) {
        console.error("Error fetching the text file:", error);
      }
    },

    async getApplicationDetails() {
      try {
			let response = await ApplicationService.get(this.applicationId);
			this.applicationDetails = response.data;

			let hasAnswers = 0;

			this.fetchPromptFileContent();
			this.fetchModelFileContent();
			this.fetchSqlFileContent();
			this.fetchSqlCreateDatabaseFileContent()
    		this.fetchSqlMockDataFileContent();

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	      try {
      		const searchDTO = {
				sortBy: "domainClassId", 
				sortOrder: "asc", 
				searchQuery: "", 
				page: "0", 
				size: "50",
				applicationId: this.applicationId 
    		};
			const response = await DomainClassService.getAllDomainClasses(searchDTO);

		    if (!response.data.empty) {
				this.domainClasses = response.data.domainClasses;
			}

//	        console.log(this.domainClasses);
      
	      } catch (error) {
	        console.error("Error fetching domainClasses:", error);
	      }
	      try {
      		const searchDTO = {
				sortBy: "submenuId", 
				sortOrder: "asc", 
				searchQuery: "", 
				page: "0", 
				size: "50",
				applicationId: this.applicationId 
    		};
			const response = await SubmenuService.getAllSubmenus(searchDTO);

		    if (!response.data.empty) {
				this.submenus = response.data.submenus;
			}

//	        console.log(this.submenus);
      
	      } catch (error) {
	        console.error("Error fetching submenus:", error);
	      }
	    
      } catch (error) {
        console.error('Error fetching application details:', error);
      }
    },
	async generateCode() {
		try {
			let response = await ApplicationService.generateCode(this.applicationDetails.applicationId);
			let result = response.data;
		} catch (error) {
			console.error('Error generating code:', error);
		}
	},
	
	async cloneApplication() {
		try {
			let response = await ApplicationService.cloneApplication(this.applicationDetails.applicationId);
			let result = response.data;
		} catch (error) {
			console.error('Error cloning application:', error);
		}
	},


	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getApplicationDetails();
  },
  computed: {
	  filteredSuggestionsApplicationName() {
	    if (!this.suggestionsLoadedApplicationName)
	      return [];
	    this.applicationNameQuery = this.applicationDetails.applicationName.toString();
	    return [
	      {
	        data: this.suggestionsApplicationName
	      }
	    ];
	  },
  },
  
};
</script>
<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.application-image {
	    height: 45px;
}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.btn_apps {
	width: 200px;	
}

.btn-starterup {
  display: inline-block;
  width: 200px;
    margin-top: 25px;
  padding: 12px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #7b7b7b;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-starterup:hover {
  background-color: #0056b3;
}
.domain-class-card {
    max-width: 160px;
    min-width: 160px;
    border-radius: 30px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;
}

.domain-class-card {
  position: relative; /* Position relative to allow absolute positioning of the tooltip */
}

.domain-class-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 150px;
  height: 40px;
  left: 50%;
  border-radius: 4px;
  background-color: #7b7b7b;  
  color: #fff;
}

.domain-class-link:hover {
  background-color: #0056b3;
  color: #fff;
}

.database-image {
    height: 100px
}

.integration-image {
    height: 100px;
    padding: 10px;
}

.content {
    margin-top: 10px
}
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: flex-start;
}

.ant-tabs-nav-list {
  width: auto !important;
}

.text-file-content {
margin-left: 75px;
  text-align: left; /* Ensures text is left-aligned */
  white-space: pre-wrap; /* Preserves formatting and wraps lines if necessary */
  font-family: monospace; /* Optional: Use a monospace font for better readability */
}

.application-image-table {
  width: 100%; /* Ensure the table takes the full width */
  max-width: 1200px;
}

.centered-cell {
  text-align: center; /* Center content in the cell */
}

.application-image {
  height: 45px; /* Maintain aspect ratio */
  margin-top: 50px;
}

</style>
