import http from "../http-common"; 

class DetailViewTableService {
  getAllDetailViewTables(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/detailViewTable/detailViewTables`, searchDTO);
  }

  get(detailViewTableId) {
    return this.getRequest(`/detailViewTable/${detailViewTableId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/detailViewTable?field=${matchData}`, null);
  }

  addDetailViewTable(data) {
    return http.post("/detailViewTable/addDetailViewTable", data);
  }

  update(data) {
  	return http.post("/detailViewTable/updateDetailViewTable", data);
  }
  
  uploadImage(data,detailViewTableId) {
  	return http.postForm("/detailViewTable/uploadImage/"+detailViewTableId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new DetailViewTableService();
