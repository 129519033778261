<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="card-plain">
          <!-- <template slot="header">
            <h4 class="card-title">Table on Plain Background</h4>
            <p class="category">Here is a subtitle for this table</p>
          </template>-->
          <div class="table-full-width text-left">
            <detailViewTable-table
            v-if="detailViewTables && detailViewTables.length > 0"
				:title="table1.title"
				:sub-title="table1.subTitle"
				:detailViewTables="detailViewTables"
				:totalElements="totalElements"
				:page="page"
				:columns="table1.columns"
 				@get-all-detail-view-tables="getAllDetailViewTables"
             >

            </detailViewTable-table>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import { Card } from "@/components/Card";

import DetailViewTableTable from "@/components/DetailViewTableTable";
import DetailViewTableService from "../services/DetailViewTableService";

const tableColumns = [];
const tableData = [
];

export default {
  components: {
    Card,
    DetailViewTableTable,
  },
  data() {
    return {
      detailViewTables: [],
	  totalElements: 0,
      page: 0,
      searchQuery: '',     
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
    };
  },
  methods: {
    async getAllDetailViewTables(sortBy='detailViewTableId',sortOrder='true',page="0",size="50") {
      try {
        try {
			const searchDTO = {
				sortBy: sortBy, 
				sortOrder: sortOrder, 
				searchQuery: this.searchQuery,
				page: page, 
				size: size
			};
	        let response = await DetailViewTableService.getAllDetailViewTables(searchDTO);
	        if (!response.data.empty) {
		        if (response.data.detailViewTables.length) {
					this.detailViewTables = response.data.detailViewTables;
				}
      			this.totalElements = response.data.totalElements;
      			this.page = response.data.page;
	        }
        } catch (error) {
          console.error("Error fetching detailViewTables:", error);
        }
        
      } catch (error) {
        console.error("Error fetching detailViewTable details:", error);
      }
    },
  },
  mounted() {
    this.getAllDetailViewTables();
  },
  created() {
    this.$root.$on('searchQueryForDetailViewTablesChanged', (searchQuery) => {
    	this.searchQuery = searchQuery;
    	this.getAllDetailViewTables();
    })
  }
};
</script>
<style></style>
