import http from "../http-common"; 

class FieldTypeService {
  getAllFieldTypes(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/fieldType/fieldTypes`, searchDTO);
  }

  get(fieldTypeId) {
    return this.getRequest(`/fieldType/${fieldTypeId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/fieldType?field=${matchData}`, null);
  }

  addFieldType(data) {
    return http.post("/fieldType/addFieldType", data);
  }

  update(data) {
  	return http.post("/fieldType/updateFieldType", data);
  }
  
  uploadImage(data,fieldTypeId) {
  	return http.postForm("/fieldType/uploadImage/"+fieldTypeId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new FieldTypeService();
